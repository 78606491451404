<template>
  <v-container fluid class="ma-0 pa-0">
    <NavTop @formReset="formReset()" />
    <v-main class="mt-12">
      <v-row class="mt-12"></v-row>
      <v-row>
        <v-col lg="3" md="2" cols="1">
          <NavDrawer />
        </v-col>
        <v-col class="mt-12" cols="12" v-if="noResults">
          <p class="title">No results found.</p>
        </v-col>
      </v-row>
      <v-row class="mt-12" v-if="!noResults">
        <v-col cols="10" lg="8" offset-lg="3" offset="1" class="mt-12">
          <v-row>
            <p class="count ml-5" v-if="isMobile">Records: {{ results.length }}</p>
            <p class="count ml-7" v-else>Records returned: {{ results.length }}</p>
            <v-spacer/>
            <download-excel
              class="download mr-6"
              :data='results'>
              Download Results
              <v-icon>mdi-download</v-icon>
            </download-excel>
          </v-row>
          <v-row>
            <v-col>
              <v-toolbar dark color="primary">
                <v-btn class="mr-8" small light v-on:click="searchReturn">Back to Search</v-btn>
                <v-toolbar-title class="white--text hidden-xs-only">Specimen Results</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-search" color="#fafafa" label="Search within results" single-line hide-details></v-text-field>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row v-resize="onResize">
            <v-col style="max-height: 70vh; overflow-y: auto" class="mr-2">
              <v-data-table :headers="headers"
                            :items="results"
                            :items-per-page="50"
                            :search="search"
                            hide-default-header
                            :footer-props="{itemsPerPageText: 'Items per Page', itemsPerPage: 50, itemsPerPageOptions: [50, 100, 200, -1]}">
                <template v-slot:item="props">
                  <tr>
                    <td>
                      <div class="table-content">
                        <v-card class="table-item text-left">
                          <p class="table-item ml-sm-1 mb-0">
                            <span :class="{'title': $vuetify.breakpoint.mdAndUp, 'subtitle-1': $vuetify.breakpoint.smAndDown}" class="font-weight-bold">
                              <router-link class="record-link" :to="{ path: `/genus/` + props.item.genus }"><i>{{props.item.genus}} </i></router-link>
                              <router-link class="record-link" :to="{ path: `/record/` + props.item.plant_id }"><i>{{props.item.species}}</i></router-link>
                              <span v-if="props.item.subspecies" class="subtitle-2"> subsp. </span>{{ props.item.subspecies }}
                              <span v-if="props.item.variety" class="subtitle-2"> var. </span>{{ props.item.variety }}
                              - <router-link class="record-link" :to="{ path: `/family/` + props.item.family }">{{ props.item.family }}</router-link>
                            </span>
                            <br>
                            <span v-if="props.item.collection_date !== 'Collection date not displayed'">
                              {{ props.item.collectors }}, {{ props.item.collectors_number }}, Collected {{ props.item.collection_date }}.
                              <br>
                            </span>
                            <span v-if="props.item.collection_date === 'Collection date not displayed'">
                              {{ props.item.collectors }}
                              <br>
                            </span>
                            <span>{{ addCounty(props.item.location_data) }}<br></span>
                            <span v-if="props.item.catalog_number[0] !== '#'">MICH{{ props.item.catalog_number }}<br></span>
                            <router-link class="record-link" :to="{ path: `/specimen-record/` + props.item.catalog_path }"><span class="font-weight-medium subtitle-1">View Full Specimen Record</span></router-link>
                          </p>
                        </v-card>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer
                    :pagination.sync="pagination"
                    :options="options"
                    :items-per-page="50"
                    :itemsPerPageOptions="[50, 100, 200, -1]"
                    @update:options="updateOptions" />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
    <TheFooter />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import NavTop from '@/components/NavTop.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'SpecimenResults',
  components: {
    NavTop,
    NavDrawer,
    TheFooter,
  },
  data: () => ({
    search: '',
    footerProps: {},
    isMobile: false,
    headers: [
      { text: 'Catalog', value: 'catalog_number' },
      { text: 'Scientific Name', value: 'scientific_name' },
      { text: 'Family', value: 'family' },
      { text: 'Collectors', value: 'collector' },
      { text: 'Collector\'s Number', value: 'collectors_number' },
      { text: 'Date Collected', value: 'collection_date' },
      { text: 'Location', value: 'location_data' },
      { text: 'Record', value: '' },
    ],
    baseURL: window.location.origin,
  }),
  computed: {
    ...mapState('specimenSearch', {
      openSearch: state => state.openSearch,
      rawResults: state => state.searchResults,
      noResults: state => state.noResults,
    }),
    results() {
      return this.responseFilter(this.rawResults);
    },
  },
  methods: {
    responseFilter(resp) { // Filters response object for relevant data
      const keys = this.dataKeys(getInitialData());
      return resp.reduce((arr, e) => {
        const obj = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const key in e) {
          if (keys.includes(key)) {
            obj[key] = e[key];
          }
        }
        obj.collection_year = obj.collection_date;
        obj.collector = obj.collectors !== null ? obj.collectors : '';
        obj.collector_number = obj.collectors_number !== null ? obj.collectors_number : '';
        obj.scientific_name = `${obj.genus} ${obj.species}`;
        obj.location_data = 'USA, Michigan'; // Next two lines to filter null values.
        obj.location_data = obj.county ? `${obj.location_data}, ${obj.county}` : obj.location_data;
        obj.location_data = obj.locality ? `${obj.location_data}: ${obj.locality}` : obj.location_data;
        obj.catalog_data = (obj.catalog_number ? obj.catalog_number : 'View Record');
        obj.catalog_path = obj.catalog_number[0] === '#' ? obj.catalog_number.slice(1) : obj.catalog_number; // Remove '#' from path.
        arr.push(obj);
        return arr;
      }, []);
    },
    dataKeys(obj) { // Gets keys from initial record object
      return Object.entries(obj)
        .reduce((arr, e) => {
          arr.push(e[0]);
          return arr;
        }, []);
    },
    onResize() {
      this.isMobile = window.innerWidth < 1264;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    addCounty(locationString) {
      const temp = locationString.split(':');
      return `${temp[0]} County:${temp[1]}`;
    },
    getGenusURL(scientificName) {
      // genus is the first word in the scientific name
      return `${this.baseURL}/genus/${scientificName.split(' ')[0]}`;
    },
    getFamilyURL(familyName) {
      return `${this.baseURL}/family/${familyName}`;
    },
    getSpeciesURL(plantID) {
      return `${this.baseURL}/record/${plantID}`;
    },
    searchReturn() {
      this.$router.push('/specimen-search');
    },
  },

};
function getInitialData() {
  // This is a duplicate definition from SpecimenSearch.vue.
  return {
    plant_id: '',
    common_name: '',
    scientific_name: '',
    genus: '',
    family: '',
    species: '',
    subspecies: '',
    variety: '',
    catalog_number: '',
    catalog_path: '',
    catalog_data: '',
    collector: '',
    collectors: '',
    collector_number: '',
    collectors_number: '',
    accompanying_collectors: '',
    collection_date: '',
    collection_year: '',
    determiner1: '',
    determiner2: '',
    determiner3: '',
    determination_history: '',
    county: '',
    location: '',
    place_name: '',
    locality: '',
    location_data: '',
    latitude: '',
    longitude: '',
    habitat: '',
    herbaria: '',
    n_results: null,
  };
}
</script>

<style lang="scss" scoped>
  .mobile {
    color: #333;
  }

  .v-data-table__wrapper table tbody tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .v-data-table__wrapper table tbody tr:nth-child(odd) {
    border-left: 6px solid #00274c;
  }

  .v-data-table__wrapper table tbody tr:nth-child(even) {
    border-left: 6px solid #b0bec5;
  }

  .v-data-table__wrapper table tbody tr td {
    display: block;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
  }

  .v-data-table__wrapper table tbody tr td ul li:before {
    content: attr(data-label);
    padding-right: .5em;
    text-align: left;
    display: block;
    color: #999;

  }

  .table-content {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
  }

  .table-item {
    padding: 5px;
    width: 100%;
    height: auto;
    font-weight: bold;
    max-height: max-content;
  }

  .record-link {
    text-decoration: underline;
    cursor: pointer;
  }

  .count {
    display: flex;
    justify-content: flex-start;
  }

  .download {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .theme--light.v-data-table .v-data-footer {
    border-top: none;
  }
  .v-list__tile__title {
    min-width: 3em;
  }

  .results-table{
    margin-top: 10vh;
    margin: 5vh;
  }


</style>
